define("discourse/plugins/retort/discourse/widgets/retort-toggle", ["exports", "virtual-dom", "discourse/lib/text", "discourse/widgets/widget", "I18n"], function (_exports, _virtualDom, _text, _widget, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("retort-toggle", {
    tagName: "button.post-retort",
    buildKey: attrs => `retort-toggle-${attrs.emoji}-${attrs.usernames.length}`,
    defaultState(_ref) {
      let {
        emoji,
        post,
        usernames
      } = _ref;
      return {
        emoji,
        post,
        usernames
      };
    },
    click() {
      const {
        post,
        emoji
      } = this.state;
      const retort = this.register.lookup("service:retort");
      retort.updateRetort(post, emoji);
    },
    html() {
      const {
        emoji,
        usernames
      } = this.state;
      return [(0, _virtualDom.h)("img.emoji", {
        src: (0, _text.emojiUrlFor)(emoji),
        alt: `:${emoji}:`
      }), usernames.length > 1 ? (0, _virtualDom.h)("span.post-retort__count", usernames.length.toString()) : "", (0, _virtualDom.h)("span.post-retort__tooltip", this.sentence(this.state))];
    },
    sentence(_ref2) {
      let {
        usernames,
        emoji
      } = _ref2;
      let key;
      switch (usernames.length) {
        case 1:
          key = "retort.reactions.one_person";
          break;
        case 2:
          key = "retort.reactions.two_people";
          break;
        default:
          key = "retort.reactions.many_people";
          break;
      }
      return _I18n.default.t(key, {
        emoji,
        first: usernames[0],
        second: usernames[1],
        count: usernames.length - 2
      });
    }
  });
});