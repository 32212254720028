define("discourse/plugins/retort/discourse/connectors/above-footer/emoji-picker-wrapper", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const retort = (0, _application.getOwner)(this).lookup("service:retort");
      retort.setPicker(component);
      const controller = (0, _application.getOwner)(this).lookup("controller:topic");
      controller.set("renderRetortPicker", false);
      component.set("renderRetortPicker", controller.get("renderRetortPicker"));
      controller.addObserver("renderRetortPicker", () => {
        if (this._state === "destroying") {
          return;
        }
        component.set("renderRetortPicker", controller.get("renderRetortPicker"));
      });
    }
  };
});